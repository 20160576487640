import React from 'react';
// import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ArticlesCardsList from '../components/ArticlesCardsList';
import SubscribePanel from '../components/SubscribePanel';
import HeroPanel from '../components/HeroPanel';

const processArticles = (/*{ data }*/) => {
  // const {
  //   allMarkdownRemark: { nodes },
  // } = data;
  const nodes = [];

  return nodes.map(({
    fields: { slug },
    frontmatter,
  }) => ({
    ...frontmatter,
    slug,
  }));
};

const IndexPage = (/*{ data }*/) => {
  const articles = processArticles(/*{ data }*/);

  return (
    <Layout>
      <SEO
        keywords={['lareclub', 'homepage']}
        title='Home'
      />
      <HeroPanel />
      <ArticlesCardsList
        title='Latest articles'
        articles={articles}
      />
      <SubscribePanel />
    </Layout>
  );
};
IndexPage.propTypes = {
  data: PropTypes.object,
}

// export const query = graphql`
// postsJson(id: { eq: "961a53ea-24f8-5f33-adc9-f36dc062499b" }) {
//   id
//   en {
//     title
    
//   }
// }


//   query {
//     allMarkdownRemark(
//       limit: 3
//       sort: { fields: [frontmatter___date], order: DESC }
//     ) {
//       nodes {
//         frontmatter {
//           title
//           date(formatString: "MMMM DD, YYYY")
//           category
//           tags
//           ...featuredImageMax800
//           cardBackgroundColor
//           description
//         }
//         fields {
//           slug
//         }
//       }
//     }
//   }
// `;

export default IndexPage;
