import React from 'react';
import PropTypes from 'prop-types';

const FormField = ({
  placeholder,
  email,
}) => (
  <input
    required
    placeholder={placeholder}
    type={email ? 'email' : 'text'}
    className='mt-4 p-4 rounded bg-brand-alabaster text-brand-input-color font-montserrat font-medium'
  />
);

FormField.propTypes = {
  email: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

const SubscribePanel = () => (
  <section className='bg-brand-black-haze pt-20 pb-20 flex flex-col items-center'>
    <div className='max-w-md'>
      <h3 className='text-brand-dark-grey text-3xl font-montserrat font-bold'>
        Keep reading this stuff
      </h3>
      <p className='text-xl font-montserrat'>
        Once a week you will recieve new articles about learning techniques
      </p>
      <form className='flex flex-col'>
        <FormField placeholder='Name' />
        <FormField placeholder='Email address' email />
        <button className='bg-brand-gamboge text-brand-white-smoke inline-block px-5 py-2 rounded-lg mt-6 font-montserrat font-semibold hover:bg-brand-hovered-link active:bg-brand-dark-grey'>
          Subscribe
        </button>
      </form>
    </div>
  </section>
);

export default SubscribePanel;