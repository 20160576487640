import React from 'react';

// import catAndHumanIllustration from '../images/cat-and-human-illustration.svg';

//background: 'rgb(22,45,41); linear-gradient(180deg, rgba(22,45,41,1) 0%, rgba(89,160,183,1) 100%);'

const HeroPanel = () => (
  <section
    className='text-center pt-20 flex flex-col items-center pb-20'
    style={{
      background: 'linear-gradient(180deg, rgba(22,45,41,1) 0%, rgba(89,160,183,1) 100%)'
    }}
  >
    <h2
      className='pb-10 flex flex-row-reverse xl:max-w-screen-xl text-left w-full text-6xl font-black'
      style={{ color: '#FBF8E9' }}
    >
      <span className='font-montserrat'>
        Learn<br/>
        Anything<br/>
        Remember<br/>
        Everything
      </span>
    </h2>
    <p
      className='leading-loose text-xl xl:max-w-screen-xl text-left font-montserrat font-semibold'
      style={{ color: '#FBF8E9'}}
    >
      The world is changing rapidly through the evolution of technology.
      New professions are emerging and some will become obsolete soon.
      That&apos;s why we write about self-learning techniques, studies and tools, so you could keep pace in the ever-changing world.
    </p>
  </section>
);

export default HeroPanel;