import React from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// https://stackoverflow.com/questions/54942047/how-to-get-previous-url-in-react-gatsby

const LinkPrevPathname = ({
  children,
  state,
  to,
  ...rest
}) => {
  const linkState = (state || {});

  return (
    <Location>
      {({ location }) => (
        <Link
          to={to}
          {...rest}
          state={{
            prevPath: location.pathname,
            ...linkState,
          }}
        >
          {children}
        </Link>
      )}
    </Location>
  );
};
LinkPrevPathname.propTypes = {
  state: PropTypes.object,
  children: PropTypes.element,
  to: PropTypes.string.isRequired,
};

export default LinkPrevPathname;