import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import LinkPrevPathname from './LinkPrevPathname';
import studyBuddyImage from '../images/study-buddy.png';

const ArticleCard = ({
  title,
  date,
  category,
  color,
  slug,
}) => (
  <div
    className='flex flex-col w-2/5 mx-2 max-w-md rounded-lg overflow-hidden shadow-brand relative hover:-mt-1 hover:mb-1 transition-all duration-200 hover:shadow-xl'
    style={{ backgroundColor: color }}
  >
    <LinkPrevPathname to={slug}>
      <picture>
        <img
          className='object-cover h-64'
          src={studyBuddyImage}
          alt='Study buddy'
        />
      </picture>
    </LinkPrevPathname>
    <div className='flex flex-col p-4 h-full'>
      <h4 className='order-2 font-semibold text-brand-dark-grey text-3xl font-montserrat'>
        <LinkPrevPathname to={slug}>
          {title}
        </LinkPrevPathname>
      </h4>
      <span className='order-1'>
        <LinkPrevPathname
          to={`/blog?category=${category}`}
          className=' hover:text-brand-dark-grey text-brand-light-gray text-2xl capitalize font-inter font-medium'
        >
          {category}
        </LinkPrevPathname>
      </span>
      <time
        className='order-3 text-brand-light-gray text-2xl mt-auto font-inter'
        dateTime={date}
      >
        {date}
      </time>
    </div>
  </div>
);
ArticleCard.propTypes = {
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

const ArticleCardsList = ({
  className,
  title,
  articles,
}) => (
  <section className={`${className || ''} pt-20 pb-20 flex flex-col items-center`}>
    <h3 className='text-brand-dark-grey text-3xl xl:max-w-screen-xl w-full font-montserrat font-bold'>
      {title}
    </h3>
    <div className='flex pt-10 w-full xl:max-w-screen-xxl justify-center'>
      {articles.map(({ slug, title, date, category, cardBackgroundColor }) => (
        <ArticleCard
          key={slug}
          slug={slug}
          title={title}
          date={date}
          category={category}
          color={cardBackgroundColor}
        />
      ))}
    </div>
    <div className='mt-10 flex justify-end w-full xl:max-w-screen-xl'>
      <Link
        to='/blog'
        className='self-end bg-brand-gamboge hover:bg-brand-hovered-link active:bg-brand-dark-grey text-brand-white-smoke inline-block px-5 py-2 rounded-lg ml-3 transition-colors duration-150 font-semibold font-montserrat'
      >
        See all posts
      </Link>
    </div>
  </section>
);
ArticleCardsList.propTypes = {
  articles: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default ArticleCardsList;